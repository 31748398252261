import React, { useEffect, useRef, useState } from "react";

import '../scss/home.scss';
import { Modal } from '../components/Modal';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';
import { useNavigate } from "react-router-dom";

import MassageK from '../img/description_block-massage.png';
import mekhanizmyMassage from '../img/mekhanizmy_massage.png';
import Chair from '../img/chair.png';
import BatteryLevel from '../img/battery-level.png';
import Man from '../img/man.png';
import Energy from '../img/energy.png';
import mekhanizm_5DAI_blockMassage from '../img/mekhanizm_5D-AI_block-massage.png';
import mekhanizm_coverage1 from '../img/mekhanizm_coverage1.png';
import mekhanizm_coverage2 from '../img/mekhanizm_coverage2.png';
import mekhanizm_coverage3 from '../img/mekhanizm_coverage3.png';
import mekhanizm_coverage4 from '../img/mekhanizm_coverage4.png';
import mekhanizm_massager from '../img/mekhanizm-massage.png';
import modes_mekhanizm1 from '../img/modes_mekhanizm1.svg';
import modes_mekhanizm2 from '../img/modes_mekhanizm2.svg';
import modes_mekhanizm3 from '../img/modes_mekhanizm3.svg';
import gravity_astronaut from '../img/gravity-astronaut.png';
import gravity_massager from '../img/gravity-massager.png';
import fujiiryokiBlack from '../img/fujiiryokiBlack.png';
import fujiiryokiBeige from '../img/fujiiryokiBeige.png';
import quality_reliability_massager from '../img/quality_reliability-massager.png';
import quality_reliability_massager__info from '../img/quality_reliability_massager__info.png';
import quality_reliability_massager__info2 from '../img/quality_reliability_massager__info2.png';
import shiatsu_acupressure__demonstration_main from '../img/shiatsu_acupressure__demonstration-main.png';
import shiatsu_acupressure__demonstration1 from '../img/shiatsu_acupressure__demonstration1.png';
import shiatsu_acupressure__demonstration2 from '../img/shiatsu_acupressure__demonstration2.png';
import stretching_fitness_trainer from '../img/stretching_fitness_trainer.png';
import stretching_fitness_trainer_light from '../img/stretching_fitness_trainer-light.png';
import full_body_stretch1 from '../img/full_body_stretch1.png';
import full_body_stretch2 from '../img/full_body_stretch2.png';
import full_body_stretch3 from '../img/full_body_stretch3.png';
import full_body_stretch4 from '../img/full_body_stretch4.png';
import full_body_stretch_bg from '../img/full_body_stretch-bg.png';
import full_body_stretch_massager from '../img/full_body_stretch-massager.png';
import infrared_heating_massage from '../img/infrared_heating-massage.png';
import manage_massage_easily_audio from '../img/manage_massage_easily-audio.svg';
import manage_massage_easily_program from '../img/manage_massage_easily-program.svg';
import manage_massage_easily_main from '../img/manage_massage_easily-main.png';
import chair_colors1 from '../img/chair_colors1.png';
import chair_colors2 from '../img/chair_colors2.png';
import flawless_build_quality_massager from '../img/flawless_build_quality-massager.png';
import flawless_build_quality_massagerLight from '../img/flawless_build_quality-massagerLight.png';
import delivery_assembly1 from '../img/delivery_assembly1.svg';
import delivery_assembly2 from '../img/delivery_assembly2.svg';
import delivery_assembly3 from '../img/delivery_assembly3.svg';

import technical_specifications_image_main from '../img/technical_specifications-image-main.png';
import technical_specifications_image1 from '../img/technical_specifications-image1.png';
import technical_specifications_image2 from '../img/technical_specifications-image2.png';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import emailjs from 'emailjs-com';

import Video from '../video/Fujiiryoki.mp4';
import VideoM from '../video/Fujiiryoki-m.mp4';
import NumberFormat from "react-number-format";

export const Home = () => {
    const video = useRef()
    const [photoActive, setPhotoActive] = useState();
    const pActive = useRef()
    const pActiveBtn = useRef()
    const [chekF, setChekF] = useState(true)
    const [chekB, setChekB] = useState();



    let navigate = useNavigate();
    useEffect(() => {
        if (photoActive !== undefined) {
            for (let i = 0; i < pActive.current.children.length; i++) {
                pActive.current.children[i].classList.remove('chair_colors__photo-active')
            }
            pActive.current.children[photoActive].classList.add('chair_colors__photo-active')
            for (let i = 0; i < pActiveBtn.current.children.length; i++) {
                pActiveBtn.current.children[i].classList.remove('chair_colors__block-color-acrive')
            }
            pActiveBtn.current.children[photoActive].classList.add('chair_colors__block-color-acrive')
        }
    }, [photoActive])



    const mcAll = useRef()
    const [mc, setMc] = useState();
    useEffect(() => {
        if (mc?.target.children[0].classList.contains('active') === true) {
            mc?.target.children[0].classList.remove('active')
            mc?.target.classList.remove('active')
        } else {

            for (let i = 1; i <= 4; i++) {
                mcAll.current.children[i].childNodes[0].classList.remove('active')
                mcAll.current.children[i].classList.remove('active')
            }
            mc?.target.children[0].classList.toggle('active')
            mc?.target.classList.toggle('active')
        }

    }, [mc])

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_8p7tccp', 'template_q9nvppd', e.target, 'user_h8FptbxZL1IEIHpQZnLeu')
        navigate("/thanks");
        // window.location.href = '/thanks';
    }


    const [nFrom, setNForm] = useState();
    const [pFrom, setPForm] = useState();

    useEffect(() => {
        if (chekF === true && nFrom?.target.value.length > 2 && pFrom?.target.value.split('_').join('').split('-').join('').split(' ').join('').length > 13) {
            setChekB(false)
        } else {
            setChekB(true)
        }
    }, [nFrom, pFrom, chekF])


    const [modal, setModal] = useState({
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false
    })

    // install Swiper modules
    SwiperCore.use([Autoplay]);


    const [vois, setVois] = useState(false);

    /* Анимация */
    useEffect(() => {
        readyHandler()
    }, [])

    const readyHandler = () => {
        const animation_active_opacity = document.querySelectorAll('.animation_active-opacity');
        const animation_active_bottom = document.querySelectorAll('.animation_active-bottom');

        setTimeout(() => {
            for (let i = 0; i < animation_active_opacity.length; i++) {
                animation_active_opacity[i].classList.remove('animation_active-opacity');
            }
            for (let i = 0; i < animation_active_bottom.length; i++) {
                animation_active_bottom[i].classList.remove('animation_active-bottom');
            }
        }, 500);
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        };
    }, [])

    const scrollHandler = () => {
        setTimeout(() => {
            const arrAnimation_active_bottom = document.querySelectorAll('.animation_active-bottom-scroll');
            const arrAnimation_active_right = document.querySelectorAll('.animation_active-right-scroll');
            const arrAnimation_active_left = document.querySelectorAll('.animation_active-left-scroll');
            const arrAnimation_active_opacity = document.querySelectorAll('.animation_active-opacity-scroll');
            const arrAnimation_active_opacity2 = document.querySelectorAll('.animation_active-opacity2-scroll');
            const arrAnimation_active_number = document.querySelectorAll('.animation_active-number-scroll');


            for (let i = 0; i < arrAnimation_active_bottom.length; i++) {
                var elementBoundary = arrAnimation_active_bottom[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_bottom[i].classList.remove('animation_active-bottom-scroll');
                }
            }
            for (let i = 0; i < arrAnimation_active_right.length; i++) {
                var elementBoundary = arrAnimation_active_right[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_right[i].classList.remove('animation_active-right-scroll');
                }
            }

            for (let i = 0; i < arrAnimation_active_left.length; i++) {
                var elementBoundary = arrAnimation_active_left[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_left[i].classList.remove('animation_active-left-scroll');
                }
            }

            for (let i = 0; i < arrAnimation_active_opacity.length; i++) {
                var elementBoundary = arrAnimation_active_opacity[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_opacity[i].classList.remove('animation_active-opacity-scroll');
                }
            }
            for (let i = 0; i < arrAnimation_active_opacity2.length; i++) {
                var elementBoundary = arrAnimation_active_opacity2[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - ((top + width) / 2) > 0) {
                    arrAnimation_active_opacity2[i].classList.remove('animation_active-opacity2-scroll');
                }
            }
            for (let i = 0; i < arrAnimation_active_number.length; i++) {
                var elementBoundary = arrAnimation_active_number[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_number.forEach(counter => {
                        let count = 0;
                        let count1000 = 1600;
                        let count100 = 350;

                        const updateCounter = () => {
                            const countTarget = parseInt(counter.getAttribute('data-counttarget'));
                            if (countTarget < 2000 && countTarget > 1601) {
                                count = count + count1000
                                count++;
                                count1000 = 1;
                            } else if (countTarget < 1600 && countTarget > 50) {
                                count = count + count100
                                count++;
                                count100 = 1
                            } else {
                                count++;
                            }
                            if (count < 40) {
                                counter.innerHTML = count;
                                arrAnimation_active_number[i].classList.remove('animation_active-number-scroll')
                                setTimeout(updateCounter, 15);
                            }
                            else if (count < countTarget) {
                                counter.innerHTML = count;
                                arrAnimation_active_number[i].classList.remove('animation_active-number-scroll')
                                setTimeout(updateCounter, 1);
                            } else {
                                counter.innerHTML = countTarget;
                            }
                        };
                        updateCounter();
                    });
                }
            }
        });
    }


    /* Видео  */
    useEffect(() => {
        if (vois === true) {
            video.current.muted = false
        } else {
            video.current.muted = 'muted'
        }
    })

    const [widthW, setWidthW] = useState();
    useEffect(() => {
        window.addEventListener('resize', setWidthW(window.innerWidth))
    }, [widthW])

    return (
        <>
            {modal.modal2 && <Modal title='Заказать Fujiiryoki' etitle='Заявка на покупку' desc='modal2' onModalClose={() => setModal({ ...modal, modal2: false })} />}
            {modal.modal3 && <Modal title='Бесплатная консультация' etitle='Заявка на звонок' desc='modal3' onModalClose={() => setModal({ ...modal, modal3: false })} />}
            {modal.modal4 && <Modal title='Получите выгодное предложение' etitle='Заявка на получения выгодного предложения' desc='modal4' onModalClose={() => setModal({ ...modal, modal4: false })} />}
            {modal.modal5 && <Modal title={'Закажите тест-драйв на дому'} etitle='>Заявка на "тест-драйв на дом"' desc='modal5' onModalClose={() => setModal({ ...modal, modal5: false })} />}
            <div className="main_block">
                <div className="main_wrapper">
                    <div className="slider_text_block">
                        <h1 className="slider_title animation_active-opacity">
                            Японское кресло
                        </h1>
                        <h2 className="slider_model animation_active-bottom">
                            Fujiiryoki JP-2000
                        </h2>
                        <p className='animation_active-bottom'>С бесплатной доставкой по РФ</p>
                        <button className='animation_active-opacity' onClick={() => setModal({ ...modal, modal3: true })}>Бесплатная консультация</button>
                    </div>
                </div>
                <div className='slider_banner3 animation_active-opacity'>
                    <div className="main_wrapper-banner__price">
                        <div className="banner__price">
                            <div className="banner__price-light"></div>
                            <div className="banner__prices">
                                <span className="newprice">545 000 ₽</span>
                                <span className="oldprice">650 000 ₽</span>
                            </div>
                            <p className="descr">Акция действует <br /> до 03.04.2025</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="description">
                <div className="main_wrapper">
                    <div className="description_block">
                        <div className="description_block-massage">
                            <img src={MassageK} className='animation_active-opacity-scroll' />
                            <div className="description_block-massage-light_top animation_active-opacity-scroll"></div>
                            <div className="description_block-massage-light_bottom animation_active-opacity-scroll"></div>
                        </div>
                        <div className="description_block-texts">
                            <h3 className="description_block-title animation_active-bottom-scroll" ><span>Настоящее</span> японское массажное кресло</h3>
                            <p className="description_block-description animation_active-bottom-scroll">Fujiiryoki JP-2000 - результат бесценных знаний за 65 лет работы и исследований в сфере массажа. Кресло делает мягкий и плавный массаж, который ощущается так, как будто он делается вручную.</p>
                            <h4 className="description_block-sub_title animation_active-bottom-scroll">...с искусственным интеллектом</h4>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="application">
                <div className="main_wrapper">
                    <div className="application_main_block">
                        <h4 className='animation_active-bottom-scroll'>Почувствуйте себя <span className='animation_active-bottom-scroll'>намного лучше</span></h4>
                        <div className="application_blocks">
                            <div className="application_blocks__row">
                                <div className="application_block animation_active-opacity-scroll">
                                    <img className="application_block__icon" src={Chair} alt="" />
                                    <p>После рабочего дня или когда чувствуете усталость</p>
                                </div>
                                <div className="application_block animation_active-opacity-scroll">
                                    <img className="application_block__icon" src={BatteryLevel} alt="" />
                                    <p>Если хотите быстро восполнить запас энергии</p>
                                </div>
                            </div>

                            <div className="application_blocks__row">

                                <div className="application_block animation_active-opacity-scroll">
                                    <img className="application_block__icon" src={Man} alt="" />
                                    <p>После длинного дня, проведенного "на ногах"</p>
                                </div>
                                <div className="application_block animation_active-opacity-scroll">
                                    <img className="application_block__icon" src={Energy} alt="" />
                                    <p>Когда нужно уменьшить боль в “проблемных зонах”</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="video">

                <span onClick={() => setVois(!vois)} className={vois === true ? 'vol_video active' : 'vol_video'} alt="" />
                <video width="100%" ref={video} height="100%" autoPlay muted id="myVideo">

                    {widthW > 780 ? <source src={Video} type="video/mp4" /> : <source src={VideoM} type="video/mp4" />}
                </video>
            </div>
            {/*  */}
            <div className="us_company">
                <div className="main_wrapper">
                    <div className="us_company_blocks">

                        <div className="us_company_block">
                            <h5 className="us_company__title animation_active-bottom-scroll">О компании</h5>
                            <p className='animation_active-bottom-scroll'>Мы являемся официальным дилером японского бренда Fujiiryoki  - создателя самых технологичных в мире кресел для массажа всего тела.</p>
                            <p className='animation_active-bottom-scroll'>   Компания была основана в Японии в 1954 году и занимает 30% мирового рынка массажных кресел, а продукция пользуется спросом более чем в двадцати странах мира.</p>
                        </div>

                        <div className="us_company_block">
                            <div className="us_company_block__info">
                                <div className="info__block animation_active-bottom-scroll">
                                    <span data-counttarget="1954" className='animation_active-number-scroll'></span>
                                    <p>Год основания</p>
                                </div>
                                <div className="info__block animation_active-bottom-scroll">
                                    <span data-counttarget="1965" className='animation_active-number-scroll'></span>
                                    <p>Год  учреждения</p>
                                </div>
                                <div className="info__block animation_active-bottom-scroll">
                                    <span data-counttarget="735" className='animation_active-number-scroll'></span>
                                    <p>Сотрудников</p>
                                </div>
                                <div className="info__block animation_active-bottom-scroll">
                                    <span>¥ <span data-counttarget="30" className='animation_active-number-scroll'></span> млн</span>
                                    <p>Капитал</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="us_company-bg animation_active-opacity2-scroll"></div>
            </div>
            {/*  */}
            <div className="advantage">
                <div className="main_wrapper">
                    <div className="advantage-bg">
                        <div className="advantage_block_top">
                            <div className="advantge_title">
                                <span className='animation_active-bottom-scroll'>Высший класс</span>
                                <span className='animation_active-bottom-scroll'>в индустрии</span>
                                <span className='animation_active-bottom-scroll'>массажных кресел</span>
                            </div>
                            <div className='advantage_sub_title__block'>
                                <p className="advantage_sub_title animation_active-opacity-scroll">
                                    Продвинутые технологии для снятия усталости и скованности в мышцах
                                </p>
                                <button className='main__button' onClick={() => setModal({ ...modal, modal4: true })}>Получить предложение</button>
                            </div>
                        </div>
                    </div>

                    <div className="advantage_block_bottom">
                        <div className="advantage_block_row">
                            <div className="advantage_block animation_active-opacity-scroll">
                                <h6><span>86</span> видов комбинаций движений</h6>
                                <p>Разминающие, постукивающие, вытягивающие массажные техники и их комбинации для глубокой проработки мышц</p>
                            </div>
                            <div className="advantage_block animation_active-opacity-scroll">
                                <h6><span>23</span> автоматические <br /> программы</h6>
                                <p>Вы всегда найдете подходящую в зависимости от ваших предпочтений</p>
                            </div>
                        </div>

                        <div className="advantage_block_row">
                            <div className="advantage_block animation_active-opacity-scroll">
                                <h6><span>12</span>  уровней <br /> глубины массажа</h6>
                                <p>Позволяют настроить массаж индивидуально и расслабить жесткие точки</p>
                            </div>
                            <div className="advantage_block animation_active-opacity-scroll">
                                <h6><span>5</span> техник растяжки <br /> для всего тела</h6>
                                <p>Вытягивающие сеансы, снимающие напряжение и заметно улучшающие самочувствие</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="mekhanizmy">
                <div className="mekhanizmy_wrapper">
                    <div className="mekhanizmy-bg">
                        <div className='mekhanizmy_wrapper_text'>
                            <h6 className="mekhanizmy_title animation_active-bottom-scroll">Инновационный массажный <span>механизм 5D-Ai</span></h6>
                            <p className="mekhanizmy_description animation_active-bottom-scroll">Массажные движения контролируются с помощью высокоточного мотора. Искусственный интеллект определяет напряженные участки, а интенсивность, глубина и плавность массажа автоматически регулируются в режиме реального времени в соответствии с индивидуальным состоянием <br /> ваших мышц</p>
                        </div>
                        <div className="mekhanizmy_massage">
                            <div className="mekhanizmyMassage"></div>
                            <img className='animation_active-opacity-scroll' src={mekhanizmyMassage} alt="" />
                            <div className="mekhanizmy_scheme animation_active-opacity-scroll"></div>
                            <div className="mekhanizmy_5dai animation_active-opacity-scroll"></div>
                        </div>
                    </div>
                    <div className="mekhanizmy-light animation_active-opacity-scroll"></div>
                </div>
            </div>
            {/*  */}
            <div className="mekhanizm_5D-AI_wrapper">
                <div className="mekhanizm_5D-AI">
                    <div className="mekhanizm_5D-AI-light animation_active-opacity-scroll"></div>
                    <div className="main_wrapper">
                        <div className="mekhanizm_5D-AI__blocks">
                            <div className="mekhanizm_5D-AI__block">
                                <img src={mekhanizm_5DAI_blockMassage} className='animation_active-opacity-scroll' alt="Fujiiryoki JP-2000 идеально подстраивается под ваше тело и его особенности" />
                            </div>
                            <div className="mekhanizm_5D-AI__block">
                                <h4 className="mekhanizm_5D-AI__title animation_active-bottom-scroll"><span>Двойное</span> сканирование</h4>
                                <p className="mekhanizm_5D-AI__description animation_active-bottom-scroll">Fujiiryoki JP-2000 идеально подстраивается под ваше тело и его особенности. Сканирование в этом кресле осуществляется двумя датчиками:</p>
                                <div>
                                    <span className='animation_active-bottom-scroll'><div></div> Определяет положение и форму позвоночника для оптимизации глубины массажа</span>
                                    <span className='animation_active-bottom-scroll'><div></div> Определяет положение плеч для оптимизации точек массажа</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="mekhanizm_coverage">
                    <div className="main_wrapper">
                        <div className="mekhanizm_coverage__blocks">
                            <div className="mekhanizm_coverage__block">
                                <h4 className="mekhanizm_coverage__title animation_active-opacity-scroll">Бесподобный <span>аэромассаж</span></h4>
                                <p className="mekhanizm_coverage__description animation_active-opacity-scroll">Массажное кресло оснащено 31-ой подушкой для аэромассажа. Интенсивность воздушно-компрессионного массажа можно настроить индивидуально для рук, плеч, бедер и ног. Воздушный массаж основан на повторении нажатия и мягкого сжатия, затем отпускания.</p>
                            </div>
                            <div className="mekhanizm_coverage_info__blocks">
                                <div className="mekhanizm_coverage_info__block animation_active-opacity-scroll">
                                    <div></div>
                                    <span>Плечи</span>
                                    <img src={mekhanizm_coverage1} alt="" />
                                </div>
                                <div className="mekhanizm_coverage_info__block animation_active-opacity-scroll">
                                    <div></div>
                                    <span>Руки</span>
                                    <img src={mekhanizm_coverage2} alt="" />
                                </div>
                                <div className="mekhanizm_coverage_info__block animation_active-opacity-scroll">
                                    <div></div>
                                    <span>Бедра</span>
                                    <img src={mekhanizm_coverage3} alt="" />
                                </div>
                                <div className="mekhanizm_coverage_info__block animation_active-opacity-scroll">
                                    <div></div>
                                    <span>Ноги</span>
                                    <img src={mekhanizm_coverage4} alt="" />
                                </div>
                            </div>
                            <div className="mekhanizm_coverage__block-img">

                                <div ref={mcAll} className="mekhanizm_coverage__block-mobile">
                                    <img className='animation_active-right-scroll' src={mekhanizm_massager} alt="Бесподобный аэромассаж" />
                                    <div onClick={e => setMc(e)} className='mekhanizm_coverage-1 animation_active-opacity-scroll'>
                                        <div className="mekhanizm_coverage_info__block-mobile">
                                            <span>Плечи</span>
                                            <img src={mekhanizm_coverage1} alt="" />
                                        </div>
                                    </div>
                                    <div onClick={e => setMc(e)} className='mekhanizm_coverage-2 animation_active-opacity-scroll'>
                                        <div className="mekhanizm_coverage_info__block-mobile">
                                            <span>Руки</span>
                                            <img src={mekhanizm_coverage2} alt="" />
                                        </div>
                                    </div>
                                    <div onClick={e => setMc(e)} className='mekhanizm_coverage-3 animation_active-opacity-scroll'>
                                        <div className="mekhanizm_coverage_info__block-mobile">
                                            <span>Бедра</span>
                                            <img src={mekhanizm_coverage3} alt="" />
                                        </div>
                                    </div>
                                    <div onClick={e => setMc(e)} className='mekhanizm_coverage-4 animation_active-opacity-scroll'>
                                        <div className="mekhanizm_coverage_info__block-mobile">
                                            <span>Ноги</span>
                                            <img src={mekhanizm_coverage4} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mekhanizm_coverage__light"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="modes_mekhanizm">
                <div className="main_wrapper">
                    <div className="modes_mekhanizm__top_blocks">
                        <div className="modes_mekhanizm__top_block">
                            <span>3 режима</span> аэромассажа
                        </div>
                        <div className="modes_mekhanizm__top_block ">
                            <p>Вы можете выбрать свой любимый режим из 3-х, все они имитируют силу и интенсивность массажа руками профессионального массажиста</p>
                        </div>
                    </div>

                    <div className="modes_mekhanizm__bottom_blocks">
                        <div className="modes_mekhanizm__bottom_block animation_active-bottom-scroll">
                            <div className="modes_mekhanizm__bottom_block__title">Режим “Массаж рук”</div>
                            <p>Аккуратно и медленно набирается воздух в компрессионные подушки, поддерживая комфортное давление</p>
                        </div>

                        <div className="modes_mekhanizm__bottom_block animation_active-bottom-scroll">
                            <img src={modes_mekhanizm1} alt="" />
                        </div>

                        <div className="modes_mekhanizm__bottom_block animation_active-bottom-scroll">
                            <div className="modes_mekhanizm__bottom_block__title">Режим “Стандартный”</div>
                            <p>Интенсивно массирует каждую область путем многократной подачи и отвода большого объема воздуха в аэроподушках</p>
                        </div>

                        <div className="modes_mekhanizm__bottom_block animation_active-bottom-scroll">
                            <img src={modes_mekhanizm2} alt="" />
                        </div>

                        <div className="modes_mekhanizm__bottom_block animation_active-bottom-scroll">
                            <div className="modes_mekhanizm__bottom_block__title">Режим “Пульсирующий”</div>
                            <p>Способствует циркуляции кровипутем многократной подачи воздуха и отвода воздуха в небольших количествах  аэроподушках</p>
                        </div>

                        <div className="modes_mekhanizm__bottom_block">
                            <img src={modes_mekhanizm3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="gravity__wrapper">
                <div className="gravity__main_blocks">
                    <div className="gravity__main_block">
                        <img className='gravity_astronaut animation_active-bottom-scroll' src={gravity_astronaut} alt="" />
                    </div>
                    <div className="gravity__main_block">
                        <h6 className='animation_active-opacity-scroll'><span>Нулевая</span> гравитация</h6>
                        <p className='animation_active-opacity-scroll'>Функция нулевой гравитации позволяет принять<br /> в нем такое положение тела, при котором происходит максимальное расслабление мышц всего тела<br /> и снижение нагрузки на позвоночник </p>
                        <button className='main__button' onClick={() => setModal({ ...modal, modal5: true })}>Заказать тест-драйв на дому</button>
                    </div>
                </div>
                <h4>Zero-G</h4>
                <div className='gravity_massagerLight'></div>
                <img className='gravity_massager' src={gravity_massager} alt="" />
            </div>
            {/*  */}
            <div className="catalog_fujiiryoki">
                <div className="main_wrapper">
                    <h4><span>Выберите ваш</span>Fujiiryoki JP - 2000</h4>
                    <div className="catalog__fujiiryoki__items">
                        <div className="catalog__fujiiryoki__item">
                            <img src={fujiiryokiBlack} alt="Fujiiryoki JP - 2000 Black" />
                            <h6>Fujiiryoki JP - 2000 Black</h6>
                            <span>545 000 ₽ <span>650 000 ₽</span></span>

                            <div>Акция действует <br /> до 03.04.2025</div>
                            <a onClick={() => setModal({ ...modal, modal2: true, title: 'Fujiiryoki' })}  >Купить<span></span></a>
                        </div>
                        <div className="catalog__fujiiryoki__item">
                            <img src={fujiiryokiBeige} alt="Fujiiryoki JP - 2000 Beige" />
                            <h6>Fujiiryoki JP - 2000 Beige</h6>
                            <span>545 000 ₽ <span>650 000 ₽</span></span>
                            <div>Акция действует <br /> до 03.04.2025</div>
                            <a onClick={() => setModal({ ...modal, modal2: true })}  >Купить<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="quality_reliability">
                <div className="main_wrapper">
                    <div className="quality_reliability__blocks">

                        <div className="quality_reliability__blocks_light animation_active-opacity-scroll"></div>
                        <div className="quality_reliability_block">
                            <div className="quality_reliability_massager__info animation_active-opacity-scroll">
                                <img src={quality_reliability_massager__info} />
                                <p>Износостойкая <br />и долговечная эко-кожа</p>
                            </div>
                            <div className="quality_reliability_massager__info2 animation_active-opacity-scroll">
                                <img src={quality_reliability_massager__info2} />
                                <p>Высококачественный<br /> пластик</p>
                            </div>
                            <img className="quality_reliability_massager animation_active-left-scroll" src={quality_reliability_massager} alt="" />
                        </div>
                        <div className="quality_reliability_block">
                            <h4 className='animation_active-bottom-scroll'>Качество<br /> и надежность</h4>
                            <p className='animation_active-bottom-scroll'>Кресла японских производителей отличаются качественными материалами и высокой надежностью. <br />Не нуждается в обременительном уходе и ремонте <br />на протяжении десятилетий при соблюдении рекомендаций производителя по эксплуатации.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ТУТ БЛОК */}

            <div className="shiatsu_acupressure">
                <div className="main_wrapper">
                    <div className="shiatsu_acupressure__top_blocks">
                        <div className="shiatsu_acupressure__top_block animation_active-opacity-scroll">
                            Точечный <br /> массаж <span> Шиацу</span>
                        </div>
                        <div className="shiatsu_acupressure__top_block">
                            <p className='animation_active-bottom-scroll'>Умный механизм массажных роликов и воздушно-компрессионных подушек, работая в тандеме, подстраиваются под эргономику ваших ног. </p>
                        </div>
                    </div>
                    <div className="shiatsu_acupressure__bottom_blocks">
                        <div className="shiatsu_acupressure__bottom_block">
                            <img className='shiatsu_acupressure__demonstration_main animation_active-left-scroll' src={shiatsu_acupressure__demonstration_main} alt="" />
                            <div className="shiatsu_acupressure__demonstration_main-light animation_active-opacity-scroll"></div>
                        </div>
                        <div className="shiatsu_acupressure__bottom_block">
                            <p className='animation_active-opacity-scroll'>Два выступающих валика: большой высокий - для тщательной проработки, и короткий поменьше - для нежного расслабления напряженного участка стопы, вытягивают и расслабляют мышцы от свода стопы до внешней стороны стопы.</p>
                            <div className="shiatsu_acupressure__bottom_demonstration_blocks">
                                <div className="shiatsu_acupressure__bottom_demonstration_block">
                                    <img className='animation_active-bottom-scroll' src={shiatsu_acupressure__demonstration1} alt="" />
                                    <p className='animation_active-opacity-scroll'>Валик с высоким выступом</p>
                                </div>
                                <div className="shiatsu_acupressure__bottom_demonstration_block">
                                    <img className='animation_active-bottom-scroll' src={shiatsu_acupressure__demonstration2} alt="" />
                                    <p className='animation_active-opacity-scroll'>Валик с меньшим выступом</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="stretching_fitness_trainer">
                <div className="stretching_fitness_trainer__wrapper">
                    <div className="stretching_fitness_trainer__blocks">
                        <div className="stretching_fitness_trainer__block">
                            <h4 className='animation_active-opacity-scroll'>Эффективная <span> растяжка</span> от финтес-тренера</h4>
                            <p className='animation_active-bottom-scroll'>Известный японский фитнес-тренер Tadashi Kaneko, разработал уникальную систему упражнений на растяжку позвоночника. <br />Массаж в кресле от Fujiiryoki 2000 JP воспроизводит эффект <br />его фитнес-системы. Главным эффектом после его упражнений является здоровая и красивая осанка, а также стройное <br />и подтянутое тело.</p>
                        </div>
                        <div className="stretching_fitness_trainer__block">
                            <img className='stretching_fitness_trainer_light animation_active-opacity-scroll' src={stretching_fitness_trainer_light} alt="" />
                            <img className='stretching_fitness_trainer-man animation_active-right-scroll' src={stretching_fitness_trainer} alt="" />
                        </div>
                    </div>
                    <div className="stretching_fitness_trainer-who">
                        <h6 className='animation_active-bottom-scroll' >Tadashi Kaneko</h6>
                        <p className='animation_active-opacity-scroll' >Фитнес-тренер по растяжке, мастер спорта <br />по кикбоксингу, автор курса “Растяжка за 3 минуты”, владелец сети магазинов спортивной одежды</p>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="full_body_stretch">
                <img className='full_body_stretch_bg animation_active-opacity-scroll' src={full_body_stretch_bg} alt="" />
                <div className="main_wrapper">
                    <div className="full_body_stretch__top_blocks">
                        <div className="full_body_stretch__top_block animation_active-opacity-scroll">
                            <span>Растяжка</span> <br /> для всего тела
                        </div>
                        <div className="full_body_stretch__top_block animation_active-bottom-scroll">
                            <p>Данный массажный комплекс эффективно разминает <br />и растягивает мышцы вашего тела, расслабляя и снимая <br />усталость. Снимает мышечное напряжение со спины <br />и восстанавливает правильную S-образную <br />линию позвоночника.</p>
                        </div>
                    </div>
                    <div className="full_body_stretch__bottom_blocks">
                        <div className="full_body_stretch__bottom_block">
                            <img className="full_body_stretch_massager animation_active-left-scroll" src={full_body_stretch_massager} alt="" />
                        </div>
                        <div className="full_body_stretch__bottom_block">
                            <div className="full_body_stretch__bottom_block-item animation_active-bottom-scroll">
                                <img src={full_body_stretch1} alt="" />
                                <div className="full_body_stretch__bottom_block-item_text">
                                    <h6>Растяжка шеи</h6>
                                    <p>Увеличивает общую гибкость тела, снимает <br />боль за счет расслабления мышц. Происходит<br /> стимуляция кровотока к головному мозгу, снимается<br /> напряжение в шейном отделе.</p>
                                </div>
                            </div>
                            <div className="full_body_stretch__bottom_block-item animation_active-bottom-scroll">
                                <img src={full_body_stretch2} alt="" />
                                <div className="full_body_stretch__bottom_block-item_text">
                                    <h6>Растяжка спины</h6>
                                    <p>Снимает напряжение и усталость, обеспечивает легкость<br /> во всем теле, способствует улучшению подвижности<br /> позвонков. Улучшается циркуляция лимфы, уменьшаются<br /> отеки.</p>
                                </div>
                            </div>
                            <div className="full_body_stretch__bottom_block-item animation_active-bottom-scroll">
                                <img src={full_body_stretch3} alt="" />
                                <div className="full_body_stretch__bottom_block-item_text">
                                    <h6>Растяжка талии</h6>
                                    <p>Помогает укрепить ткани <span> и вытеснить жировые<br /> отложения.</span> <span> Уменьшается отложение солей, улучшается<br /> поступление кислорода к головному мозгу.</span></p>
                                </div>
                            </div>
                            <div className="full_body_stretch__bottom_block-item animation_active-bottom-scroll">
                                <img src={full_body_stretch4} alt="" />
                                <div className="full_body_stretch__bottom_block-item_text">
                                    <h6>Растяжка ног</h6>
                                    <p>Ноги и поясница фиксируются воздушными подушками,<br /> затем ноги сгибаются в коленях. </p><p> Мышцы растягиваются, тем самым улучшается<br />  подвижность суставов.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='main__button' onClick={() => setModal({ ...modal, modal5: true })}>Хочу протестировать перед покупкой</button>
            </div>
            {/*  */}
            <div className="infrared_heating">
                <div className="main_wrapper">
                    <div className="infrared_heating__blocks">
                        <div className="infrared_heating__block">
                            <img src={infrared_heating_massage} alt="" className='animation_active-opacity-scroll' />
                        </div>
                        <div className="infrared_heating__block">
                            <div>
                                <h4 className='animation_active-opacity-scroll'>Инфракрасный <span>прогрев</span></h4>
                                <p className='animation_active-bottom-scroll'>Сеанс массажа стал еще эффективнее и комфортнее <br />с двойным инфракрасным прогревом, который проникает глубоко в ткани. Это улучшает циркуляцию крови, ускоряет метаболизм, уменьшает боли <br />в мышцах и оказывает расслабляющий эффект.</p>
                                <div>
                                    <p className='animation_active-bottom-scroll'>В спинке кресла находится накидка с инфракрасными нагревателями, с помощью которой можно прогреть как область спины, так и область живота.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="manage_massage_easily">
                <div className="manage_massage_easily__blocks">
                    <div className="manage_massage_easily__block">
                        <h4 className='animation_active-opacity-scroll'><span>Управляйте</span> массажем легко</h4>
                        <p className='animation_active-bottom-scroll'>Интуитивно понятный, удобной эргономичной <br />формы, оснащен ярким цветным дисплеем. Благодаря крупным символам и улучшенной видимости вы легко выберете ваш любимый массаж.</p>
                        <div className="manage_massage_easily__block__bottom-main">
                            <div className="manage_massage_easily__block__bottom animation_active-bottom-scroll">
                                <div>
                                    <img src={manage_massage_easily_audio} alt="" />
                                </div>
                                <p >Аудио-руководство с голосовым <br />управлением сделает сеансы массажа <br />еще проще и удобнее </p>
                            </div>
                            <div className="manage_massage_easily__block__bottom animation_active-bottom-scroll">
                                <div>
                                    <img src={manage_massage_easily_program} alt="" />
                                </div>
                                <p >В любой программе можно повторить <br />или пропустить воздействие одним <br />нажатием кнопки </p>
                            </div>
                        </div>
                        <button className='main__button' onClick={() => setModal({ ...modal, modal5: true })}>Хочу протестировать перед покупкой</button>
                    </div>
                    <div className="manage_massage_easily__block">
                        <img className='manage_massage_easily_main animation_active-right-scroll' src={manage_massage_easily_main} alt="" />
                    </div>
                    <div className="manage_massage_easily_main-light animation_active-opacity-scroll"></div>
                </div>
            </div>
            {/*  */}
            <div className="chair_colors">
                <div className="main_wrapper">
                    <div className="chair_colors__block__main_block">
                        <div className="chair_colors__block-title animation_active-opacity-scroll"><h4>Универсальные <span>цветовые</span> решения</h4></div>
                        <div ref={pActiveBtn} className="chair_colors__block-color">
                            <div onClick={() => setPhotoActive(0)} className='animation_active-opacity-scroll chair_colors__block-color-acrive'><span>Black</span></div>
                            <div onClick={() => setPhotoActive(1)} className='animation_active-opacity-scroll'><span>Beige</span></div>
                        </div>
                        <div ref={pActive} className="chair_colors__block-photo">
                            <img className='chair_colors__photo-active' src={chair_colors1} alt="" />
                            <img src={chair_colors2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="flawless_build_quality">
                <div className="main_wrapper">
                    <div className="flawless_build_quality__blocks">
                        <div className="flawless_build_quality__block">
                            <img className='flawless_build_quality_massagerLight animation_active-opacity-scroll' src={flawless_build_quality_massagerLight} alt="" />
                            <img className='flawless_build_quality_massage animation_active-opacity-scroll' src={flawless_build_quality_massager} alt="" />
                        </div>
                        <div className="flawless_build_quality__block">

                            <Swiper spaceBetween={30} centeredSlides={true} autoplay={{
                                "delay": 2000,
                                "disableOnInteraction": false
                            }} className="mySwiper">
                                <SwiperSlide><h4><span>Безупречное</span> качество сборки</h4></SwiperSlide>
                                <SwiperSlide><h4><span> Идеальная </span> форма корпуса</h4></SwiperSlide>
                                <SwiperSlide><h4><span>Современный </span>дизайн</h4></SwiperSlide>
                            </Swiper>



                            <p className='animation_active-bottom-scroll'>Покупая массажное кресло Fujiiryoki <br />JP-2000, вы выбираете лучшее.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="delivery_assembly">
                <div className="main_wrapper">
                    <h4 className='animation_active-opacity-scroll'>Бесплатная доставка и сборка<br /> в любой точке России</h4>
                    <div className="delivery_assembly__blocks">
                        <div className="delivery_assembly__block animation_active-bottom-scroll">
                            <img src={delivery_assembly1} alt="" />
                            <h6>Первый запуск</h6>
                            <p>Первый запуск выполняется в присутствии<br /> нашего менеджера, который приедет<br /> для этого в любую точку РФ</p>
                        </div>
                        <div className="delivery_assembly__block animation_active-bottom-scroll">
                            <img src={delivery_assembly2} alt="" />
                            <h6>Доставка в любую точку РФ</h6>
                            <p>Доставка кресла выполняется до места<br /> непосредственной установки – <br />город, улица, дом, комната</p>
                        </div>
                        <div className="delivery_assembly__block animation_active-bottom-scroll">
                            <img src={delivery_assembly3} alt="" />
                            <h6>Только оригинал</h6>
                            <p>Покупая у оригинального бренда, вы<br /> приобретаете только сертифицированный<br /> и оригинальный продукт</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="technical_specifications">
                <div className="main_wrapper">
                    <div className="technical_specifications__title animation_active-bottom-scroll">Технические характеристики<span>FUJIIRYOKI <br /> JP-2000</span></div>
                    <div className="technical_specifications__blocks">
                        <div className="technical_specifications__tables">
                            <div>
                                <table className="animation_active-opacity-scroll">
                                    <tr>
                                        <td>Срок гарантии:</td>
                                        <td>5 лет</td>
                                    </tr>

                                    <tr>
                                        <td>Количество автоматических <br />программ:</td>
                                        <td>23</td>
                                    </tr>
                                    <tr>
                                        <td>Длина массажной каретки:</td>
                                        <td>80 см</td>
                                    </tr>
                                    <tr>
                                        <td>Максимальное время массажа:</td>
                                        <td>30 мин</td>
                                    </tr>
                                    <tr>
                                        <td>Вес:</td>
                                        <td>78 кг</td>
                                    </tr>
                                    <tr>
                                        <td>Размеры в положении лежа (ДхШхВ):</td>
                                        <td>200 х 88 х74 см</td>
                                    </tr>
                                    <tr>
                                        <td>Типы массажа:</td>
                                        <td>Роликовый / Воздушно-компрессионный / Шиацу <br />/ ИК прогрев</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <table className="animation_active-opacity-scroll">
                                    <tr>
                                        <td>Максимальный вес пользователя:</td>
                                        <td>130 кг</td>
                                    </tr>
                                    <tr>
                                        <td>Воздушно-компрессионный массаж:</td>
                                        <td>бёдра / икры /плечи / <br />руки / стопы / ягодицы</td>
                                    </tr>
                                    <tr>
                                        <td>Количество массажных роликов</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>Потребляемая мощность:</td>
                                        <td>115 Вт</td>
                                    </tr>
                                    <tr>
                                        <td>Размеры в положении сидя (ДхШхВ):</td>
                                        <td>138 х 76 х 125 см</td>
                                    </tr>
                                    <tr>
                                        <td>Зоны воздействия:</td>
                                        <td>голени / плечи / поясница /<br />руки / спина / стопы / шея /<br />ягодицы</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="technical_specifications-images">
                            <div className="technical_specifications-images-title animation_active-bottom-scroll">
                                Габаритные размеры
                            </div>
                            <div className="technical_specifications-images__blocks">
                                <img src={technical_specifications_image_main} className="animation_active-opacity-scroll" alt="Габаритные размеры" />
                                <div className="technical_specifications-images__block">
                                    <img src={technical_specifications_image1} className="animation_active-opacity-scroll" alt="Габаритные размеры" />
                                </div>
                                <div className="technical_specifications-images__block">
                                    <img src={technical_specifications_image2} className="animation_active-opacity-scroll" alt="Габаритные размеры" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="call_back">
                <div className="main_wrapper">
                    <form className="call_back-form" method="get" onsubmit="yaCounter86637692.reachGoal('ORDER'); return true;" onSubmit={sendEmail}>
                        <h6>Оставьте заявку</h6>
                        <div className='call_back__wrapper'>
                            <p>И мы свяжемся с вами и ответим <br />на все ваши вопросы</p>
                            <input className='call_back-input' type="text" onChange={(e) => setNForm(e)} name='name' placeholder='Ваше имя' onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" autocomplete="off" onDrag="return false" onDrop="return false" autocomplete='off' />
                            <NumberFormat className='call_back-input' placeholder='Телефон' format="+7 (###) ###-##-###" onMouseEnter={(e) => e.target.placeholder = '+7 (___) ___-__-___'} onMouseLeave={(e) => e.target.placeholder = 'Телефон'} onChange={(e) => setPForm(e)} type="text" name='phone' mask="_" />
                            <div>
                                <input className="call_back-checkbox" type="checkbox" checked={chekF} onChange={() => setChekF(!chekF)} />
                                <p>Я ознакомлен и согласен <a href="./doc/Privacy_Policy.pdf">с условиями политики
                                    обработки персональных данных и конфиденциальности</a></p>
                            </div>
                            <button className="call_back-button" disabled={chekB} type='submit' >Отправить заявку</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
